'use strict';

var GoogleFonts = function () {
  var WebFont = require('webfontloader');
  WebFont.load({
    google: {
      families: ['Nunito:400,400i,700,700i:latin-ext']
    }
  });
}
module.exports = GoogleFonts;
