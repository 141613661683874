/*
  Project: Ps2018
  Author: Krzysztof Malina
*/
/*
var $ = require('jquery');
window.jQuery = window.$ = $; // You expose jQ as a global object




var secondaryNav = require('./secondary-nav.js');
var taps = require('./taps.js');
var clickable = require('./clickable.js');
var heights = require('./heights.js');
var detectFlexbox = require('./detectflexbox.js');

var animateTestimonials = require('./testimonials.js');


detectFlexbox();

animateTestimonials();

secondaryNav();
taps();
clickable();

heights();
*/
var externalLinks = require('./external-links.js');
var googleFonts = require('./googlefonts.js');
var mobileNav = require('./mobilenav.js');
var cookies = require('./cookies.js');

googleFonts();
mobileNav();
cookies();
externalLinks();
