'use strict';

var mobileNav = function () {
  var menuWrapper = document.querySelector('.c-sliding-nav'),
    html = document.querySelector('html'),
    body = document.querySelector('body'),
    trigger = document.getElementById('menu-trigger');

    trigger.onclick = function() {
      this.classList.toggle('is-active');
      menuWrapper.classList.toggle('c-sliding-nav--slidein');
      html.classList.toggle('u-no-scroll');
      body.classList.toggle('u-no-scroll');
    }
};

module.exports = mobileNav;
